const profiles = [
  {
    name: "Meredith Holbrook",
    role: "Founder\nCEO",
    aboutLine: "the Founder and CEO of Rily.",
    about:
      "While I’ve always loved to cook - my professional background began in photojournalism. I’ve spent a good chunk of my life working with humanitarian aid organizations; traveling, learning about different cultures and enjoying a few good meals along the way!\n\nI moved back to my hometown of Toronto in 2017, and soon after, my Father was diagnosed with cancer. Cooking with nutrition in mind quickly became a central priority.\n\nI’ve always been passionate about feeding those I love, gathering together, and making sure everyone would be included no matter their dietary restriction. However, when researching the best food options for my Father’s diagnoses, I found myself flooded by the web of contradictory information. There was a serious lack of reliable sites when it came to treating ailments with proper nutrition.\n\nNot only did I want to figure out what cancer patients should, and shouldn’t eat, I also wanted to find recipes that he would enjoy. Recipes that would take his mind off of his illness, and not make him feel like he was on a diet. I wanted our meals to be a happy time where we could sit in the present and create memories.\n\nWhen he passed, I was devastated - but the emotions that overwhelmed me were the same ones that propelled me to create what we now know of as Rily.\n\nPeople get diagnosed with life-altering ailments every day. I couldn’t understand why there wasn’t an accessible tool to help them navigate their nutrition. Something that could give them and their loved ones comfort – that through the chaos, there is one thing that is in their control: food.",
    questions: {
      favoriteFood: "Plain penne pasta with butter, Parmesan, salt & pepper",
      deathRowMeal: "Same as above",
      goToIngredient: "EVOO, Rosemary & Cayenne",
      favoriteRilyRecipe: "Tina’s Braised Beef Tacos",
      favoriteMindfullnessHack: "Long walks (not on the beach)",
    },
    img: "https://res.cloudinary.com/rily/image/upload/q_auto,dpr_auto,w_1200/v1639162971/assets/team/b66c214aec4b5d0c958321f9ac6d38db_ibkoum.jpg",
  },
  {
    name: "Carly Nadine",
    role: "Herbalist Nutritionist\nWellness Consultant",
    aboutLine:
      "an herbalist, registered holistic nutritionist, and wellness consultant.",
    about:
      "My focus is on how lifestyle, nutrition, and the use of healing herbs can bring back balance to your body. My specialty is in healing the relationship between the gut and mind. I have helped countless clients with ailments ranging from poor digestion to stress and anxiety, as well as restoring energy and vitality.",
    questions: {
      favoriteFood: "Ethiopian or Middle Eastern",
      deathRowMeal: "Steak Frites with a full bodied red wine",
      goToIngredient: "Herbs: Ginger, Turmeric, Rosemary, Parsley & Thyme",
      favoriteRilyRecipe: "Turmeric Roasted Cauliflower",
      favoriteMindfullnessHack:
        "A few minutes of deep breathing. We breathe constantly but are almost always unconscious of its activity. Taking time to focus on breathing helps individuals find a connection with their bodies.",
    },
    img: "https://res.cloudinary.com/rily/image/upload/q_auto,dpr_auto,w_1200/v1639162972/assets/team/7b521de0f49885c670db4896035f6119_qou9ny.jpg",
  },
  {
    name: "Damien Zielinski",
    role: "Functional Medicine Practitioner",
    aboutLine:
      "a cloud-based functional medicine practitioner with a focus on mental health and insomnia.",
    about:
      "While my clinical practice uses functional lab testing, natural supplements, and 21st-century lifestyle hacks to get to the root of chronic complex health conditions, my background in holistic nutrition makes sure that whole foods and dietary augmentations are at the heart of every single protocol. I bring scientific rigor, attention to detail, and powered research skills to the Rily team.",
    questions: {
      favoriteFood: "Duck Confit",
      deathRowMeal: "Egg Florentine",
      goToIngredient: "Garlic",
      favoriteRilyRecipe: "Miso Mustard Brussels Sprouts",
      favoriteMindfullnessHack: "Qigong breathing",
    },
    img: "https://res.cloudinary.com/rily/image/upload/q_auto,dpr_auto,w_1200/v1639162971/assets/team/512ed94ea4a8e585082c41b6564664ca_kmuotq.jpg",
  },
  {
    name: "Dani Weins",
    role: "Rily Kitchen Manager",
    aboutLine: "recipe developer and kitchen manager for rily",
    about:
      "Dani has been interested in nutrition since she was a child, and had her first experiences with diet-related health issues. This introduction sparked a passion that culminated in formal education and in 2018 she graduated from the Institute of Holistic Nutrition. Since then she has focused on working in the food industry as a recipe developer, menu designer, copywriter, and nutrition consultant. Dani continues to work with food, bringing her personal perspective on nutrition to all she does.",
    img: "https://res.cloudinary.com/rily/image/upload/c_crop,q_auto,dpr_auto,w_1200,h_750/v1648764251/assets/team/headshot_zgnc93.jpg",
  },
]

export default profiles
