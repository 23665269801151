import React, { useEffect, useState } from "react"
import styled from "styled-components"
import {
  Title,
  MediumTitle,
  SansTitle,
} from "../../components/typography/title"
import { Text } from "../../components/typography/paragraph"
import { getLocalizedString } from "../../utils/localization"
import profiles from "./team/data"
import close from "../../static/images/close.svg"
import SEO from "../../components/seo"
import { slug } from "../../utils/slug"

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  background-color: ${props => props.theme.colors.floralWhite};
  min-height: calc(80vh - 92px);
  height: fit-content;
  padding: 20px 20px 40px;
  display: grid;
  justify-content: center;
  align-content: center;

  a {
    width: fit-content;
    height: fit-content;
  }
  @media (min-width: 768px) {
    padding: 46px 55px;
  }
`

const Intro = styled(Title)`
  margin: 0 auto;
  @media (min-width: 768px) {
    text-align: center;
  }
`

const Middle = styled(Container)`
  background-color: ${props => props.theme.colors.white};
  padding: 20px;
  @media (min-width: 768px) {
    padding: 104px calc(16.66vw + 40px) 0;
    display: flex;
  }
`

const Left = styled(SansTitle)`
  margin-bottom: 40px;
  @media (min-width: 768px) {
    width: 16.66vw;
    margin-right: 8.33vw;
  }
`

const Right = styled.div`
  @media (min-width: 768px) {
    width: 41.6666667vw;
  }
`

const Tall = styled.img`
  width: auto;
  height: 50vh;
  border-radius: 20px;
  margin-right: 20px;
  @media (min-width: 768px) {
    transform: ${props => (props.offsetY ? "translateY(25%)" : "none")};
    width: calc(25% - 15px);
    height: auto;
  }
`

const Wide = styled.img`
  width: auto;
  height: 50vh;
  border-radius: 20px;
  @media (min-width: 768px) {
    width: calc(50% - 10px);
    height: auto;
  }
`

const ImgWrapper = styled.div`
  width: calc(100vw - 40px);
  display: flex;
  align-self: center;
  align-items: center;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 768px) {
    width: calc(100vw - 110px);
    overflow-x: initial;
  }
`

const ProfileSection = styled.div`
  max-width: calc(100vw - 40px);
  margin: auto;
  @media (min-width: 768px) {
    max-width: calc(66.66vw - 80px);
  }
`

const ProfileBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 20px;
  margin-bottom: 50px;
`

const ProfileImage = styled.div`
  width: calc(50vw - 30px);
  height: calc(50vw - 30px);
  margin-bottom: 10px;
  border-radius: 20px;
  background-image: ${props =>
    props.isActivated
      ? "linear-gradient(0deg,rgba(255, 199, 0, 0.5) 0%, rgba(255, 199, 0, 0.5) 100%), " +
        `${props.bgSrc}`
      : props.bgSrc
      ? props.bgSrc
      : null};
  background-size: cover;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-image: linear-gradient(
          0deg,
          rgba(255, 199, 0, 0.5) 0%,
          rgba(255, 199, 0, 0.5) 100%
        ),
        ${props => (props.bgSrc ? props.bgSrc : null)};
    }
  }

  @media (min-width: 768px) {
    width: calc(33.33vw - 50px);
    height: calc(33.33vw - 50px);
  }
`

const SectionTitle = styled(SansTitle)`
  margin: 20px 0 43px 0;
  @media (min-width: 768px) {
    margin: 40px 0 43px 0;
  }
`

const Name = styled(MediumTitle)`
  margin-bottom: 0;
`

const ProfileTitle = styled(MediumTitle)`
  color: ${({ theme }) => theme.colors.gray};
  white-space: pre-wrap;
`

const Profile = styled.div`
  margin-top: ${props => (props.isActivated ? "30px" : "initial")};
  margin-bottom: ${props =>
    props.isActivated ? `${props.marginBottom + "px"}` : "initial"};
  height: auto;
`

const AboutWrapper = styled.div`
  white-space: pre-wrap;
  background-color: ${props => props.theme.colors.floralWhite};
  position: absolute;
  left: 0;
  padding: 44px 20px;
  margin-bottom: 105px;
  visibility: ${props => (props.isActivated ? "visible" : "hidden")};
  @media (min-width: 768px) {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    padding: 44px 0;
  }
`

const AboutContent = styled.div`
  width: calc(100vw - 40px);
  left: 0;
  @media (min-width: 768px) {
    width: calc(66.66vw - 80px);
  }
`

const AboutTitle = styled(Title)`
  width: calc(16.66vw - 110px);
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin-bottom: 0;
    font-size: 20px;
  }
`

const Close = styled.div`
  cursor: pointer;
  width: calc(16.66vw - 110px);
  height: fit-content;
  position: absolute;
  right: 40px;
  top: 44px;
  @media (min-width: 768px) {
    margin-bottom: 40px;
    position: relative;
    top: initial;
    right: initial;
  }
`

const Underline = styled.div`
  background-color: ${props => props.theme.colors.yellow};
  width: 93px;
  height: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin-bottom: 40px;
  }
`

const About = () => {
  const [isAboutEnabled, toggleAbout] = useState(false)
  const [currentProfile, updateAbout] = useState(null)
  const [sliderHeight, updateSliderHeight] = useState(null)

  useEffect(() => {
    function handleResize() {
      if (currentProfile === null) {
        return
      } else if (currentProfile !== null) {
        const aboutDiv = document.getElementById(
          "about-slider" + currentProfile
        )
        const elementHeight = aboutDiv.offsetHeight + 40
        updateSliderHeight(elementHeight)
      }
    }
    window.addEventListener("resize", handleResize)
  })

  const enableAboutDropdown = index => {
    if (index === null) {
      updateSliderHeight(0)
      toggleAbout(!isAboutEnabled)
      updateAbout(index)
    } else if (isAboutEnabled === true && currentProfile === index) {
      toggleAbout(!isAboutEnabled)
      updateAbout(null)
    } else {
      const aboutdiv = document.getElementById("about-slider" + index)
      const elementHeight = aboutdiv.offsetHeight + 40
      updateSliderHeight(elementHeight)
      toggleAbout(!isAboutEnabled)
      updateAbout(index)
    }
  }

  let teamProfileCards = profiles.map((item, index) => {
    return (
      <Profile
        key={index}
        isActivated={currentProfile === index ? true : false}
        marginBottom={sliderHeight}
      >
        <div>
          <ProfileImage
            key={index}
            id={"about" + index}
            onClick={() => enableAboutDropdown(index)}
            bgSrc={"url(" + `${item.img}` + ")"}
            isActivated={currentProfile === index ? true : false}
          />
          <Name>{item.name}</Name>
          <ProfileTitle>{item.role}</ProfileTitle>
          {currentProfile === index ? <Underline /> : null}
        </div>
        <AboutWrapper
          key={index}
          id={"about-slider" + index}
          isActivated={currentProfile === index ? true : false}
        >
          <AboutTitle>About</AboutTitle>
          <AboutContent>
            <MediumTitle>
              Hi, I'm {item.name} – {item.aboutLine}
            </MediumTitle>
            <Text>{item.about}</Text>
            {/* Formatting of this Q&A still needs to be designed***
              <br/>
              <CaptionText>Favorite Food: {item.questions.favoriteFood}</CaptionText>
              <CaptionText>Death-Row Meal: {item.questions.deathRowMeal}</CaptionText>
              <CaptionText>Go to Ingredient: {item.questions.goToIngredient}</CaptionText>
              <CaptionText>Favourite Rily Recipe: {item.questions.favoriteRilyRecipe}</CaptionText>
              <CaptionText>Favourite mindfulness hack: {item.questions.favoriteMindfullnessHack}</CaptionText> */}
          </AboutContent>
          <Close onClick={() => enableAboutDropdown(null)}>
            <img src={close} />
          </Close>
        </AboutWrapper>
      </Profile>
    )
  })

  return (
    <AboutContainer>
      <SEO title={"About Us"} pagePath={slug("about_us")} />
      <Container>
        <Intro>{getLocalizedString("about.intro")}</Intro>
      </Container>

      <Middle>
        <Left>{getLocalizedString("about.left.heading")}</Left>
        <Right>
          <MediumTitle>{getLocalizedString("about.p1")}</MediumTitle>
          <MediumTitle>{getLocalizedString("about.p2")}</MediumTitle>
          <MediumTitle>{getLocalizedString("about.p3")}</MediumTitle>
        </Right>
      </Middle>

      <ProfileSection>
        <SectionTitle>{getLocalizedString("about.meet.our.team")}</SectionTitle>
        <ProfileBlock>{teamProfileCards}</ProfileBlock>
      </ProfileSection>

      <Container>
        <ImgWrapper>
          <Tall src="https://images.unsplash.com/photo-1601002357064-e43894c23107?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"></Tall>
          <Tall
            offsetY
            src="https://images.unsplash.com/photo-1528712306091-ed0763094c98?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80"
          ></Tall>
          <Wide src="https://images.unsplash.com/photo-1601001815853-3835274403b3?ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"></Wide>
        </ImgWrapper>
      </Container>
    </AboutContainer>
  )
}

export default About
